import React, { useContext } from "react";
import { Routes } from "@mnemonic/router";
import { SessionContext } from "@mne-authentication/session2";
import isEnvironmentArgus from "./Helpers/isEnvironmentArgus";
import { getRouteDefinitions } from "./Routes/routeDefinitions";
const Content = () => {
    const { session } = useContext(SessionContext);
    const routeDefinitions = getRouteDefinitions({ isEnvironmentArgus: isEnvironmentArgus() });
    return <Routes context={{ session }} routeDefinitions={routeDefinitions}/>;
};
export default Content;
