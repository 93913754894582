import React, { useCallback } from "react";
import { hasPermission } from "@mne-authentication/api/currentuser";
import { Session } from "@mne-authentication/session2";
const ApplicationSession = ({ apiUrl, session, children }) => {
    const checkPermission = useCallback(({ customerId, name }) => {
        if (!session) {
            return Promise.reject(new Error("No session available"));
        }
        if (!customerId) {
            return Promise.resolve(session.functions.some((func) => func.name === name));
        }
        return hasPermission(name, customerId, { apiUrl }).then(({ response }) => Boolean(response.ok));
    }, [session]);
    return (<Session {...{
        checkPermission,
        loading: false,
        session: session,
    }}>
      {children}
    </Session>);
};
export default ApplicationSession;
