import { List, Record } from "immutable";
/**
 * This record is used for making requests against the PassiveDNS search endpoint with custom criteria,
 * other than the simple query-string.
 *
 * @type {Immutable.Record}
 */
class RequestRecord extends Record({
    query: "",
    aggregateResult: true,
    includeAnonymousResults: true,
    rrClass: List([]),
    rrType: List([]),
    customerID: List([]),
    tlp: List([]),
    offset: 0,
    limit: 25,
    customers: List([]),
}) {
}
export default RequestRecord;
