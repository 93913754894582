/* eslint-disable you-dont-need-lodash-underscore/uniq */
/* eslint-disable you-dont-need-lodash-underscore/omit */
/* eslint-disable you-dont-need-lodash-underscore/flatten */
import React from "react";
import flatten from "lodash/flatten";
import isEqual from "lodash/isEqual";
import omit from "lodash/omit";
import pick from "lodash/pick";
import uniq from "lodash/uniq";
const makeCancelable = (promise) => {
    let hasCanceled = false;
    const wrappedPromise = new Promise((resolve, reject) => {
        promise.then(
        // eslint-disable-next-line no-confusing-arrow,prefer-promise-reject-errors
        (val) => (hasCanceled ? reject({ isCanceled: true }) : resolve(val)), 
        // eslint-disable-next-line no-confusing-arrow,prefer-promise-reject-errors
        (error) => (hasCanceled ? reject({ isCanceled: true }) : reject(error)));
    });
    return {
        promise: wrappedPromise,
        cancel() {
            hasCanceled = true;
        },
    };
};
const assertDataLoaderResultIsPromise = (result) => {
    if (!result || typeof result.then !== "function") {
        // This is intentional
        // $FlowFixMe
        throw new Error(`withDataLoader: dataLoader must always return a Promise, got ${result}`);
    }
};
/**
 * withDataLoader is a HOC that fetches required data for a component using a supplied dataLoader
 *
 * @param dataLoader A function returning a Promise either resolving with an object which will be merged with the props passed down, or rejecting with an error
 * @param options A set of options on how the dataLoader should behave.
 */
const withDataLoader = (dataLoader, options = {}) => (Component) => {
    class WithDataLoader extends React.Component {
        state = {
            data: null,
            error: null,
            isLoading: true,
            startLoadTime: null,
        };
        _cancelable;
        componentDidMount() {
            this.loadData(this.props);
        }
        componentDidUpdate(oldProps) {
            if (!isEqual(oldProps, this.props)) {
                if (this.shouldLoadData(oldProps)) {
                    this.loadData(this.props);
                }
            }
        }
        componentWillUnmount() {
            this.stopLoadingData();
        }
        /**
         * shouldLoadData decides when the component receives props if the dataLoader should re-run.
         * If a supplied `shouldLoadData` function is present that function is used instead.
         * If `watchProps` is present true is only returned if any of the specified keys in props have changed.
         * Else shouldLoadData only returns true if any props have changed.
         * Uses shallowEqual to compare the props.
         * @returns {boolean} True if the dataLoader should be re-run, False if not
         */
        shouldLoadData = (oldProps) => {
            if (options.shouldLoadData) {
                return options.shouldLoadData(oldProps, this.props);
                // Allow empty arrays to explictly never refetch data
            }
            if (options.watchProps) {
                return !isEqual(pick(oldProps, options.watchProps || []), pick(this.props, options.watchProps || []));
            }
            // Never reload data if no props have changed
            // The data loader should fetch data based on props, and if no props have changed, the data should be the same
            // forceFetch can be called after doing mutable actions
            return !isEqual(oldProps, this.props);
        };
        /**
         * loadData calls the supplied dataLoader, waiting for it's promise to resolve before updating the data state
         * The promise returned from the dataLoader is made cancelable using the `makeCancelable` helper function.
         * Every time this function is called the previous promise is always canceled, thus guaranteeing the latest dataLoader call is the one updating data.
         * @param props
         */
        loadData = (props) => {
            this.setState({
                error: null,
                isLoading: true,
                startLoadTime: new Date(),
            });
            this.stopLoadingData();
            const promise = dataLoader(props);
            assertDataLoaderResultIsPromise(promise);
            // eslint-disable-next-line no-underscore-dangle
            this._cancelable = makeCancelable(promise);
            // eslint-disable-next-line no-underscore-dangle
            this._cancelable.promise.then((data) => this.setState({ data, isLoading: false, startLoadTime: null }), (error) => {
                // Disregard canceled promises
                if (error && error.isCanceled) {
                    return;
                }
                this.setState({ error, isLoading: false, startLoadTime: null });
            });
        };
        /**
         * forceFetch forces a new reload of the data, re-running dataLoader
         * Useful after doing mutable actions.
         *
         * If the dataLoader is named and a forceFetch function is present it first calls the parent forceFetch, then it's own.
         * If the dataLoader is not named we only call the parent forceFetch function, because then we assume this dataLoader is part of a chain.
         * Else we re-run the dataLoader function.
         */
        forceFetch = () => {
            if (this.props.forceFetch && typeof this.props.forceFetch === "function" && options.name) {
                this.props.forceFetch();
                this.loadData(this.props);
            }
            else if (this.props.forceFetch && typeof this.props.forceFetch === "function") {
                this.props.forceFetch();
            }
            else {
                this.loadData(this.props);
            }
        };
        /**
         * onCancel sets the isLoading state to false and cancels any dataLoading that might be going on.
         * If a parent onCancel is provided it will also be called
         */
        onCancel = () => {
            if (typeof this.props.onCancel === "function") {
                this.props.onCancel();
            }
            this.stopLoadingData();
            this.setState({ isLoading: false });
        };
        /**
         * stopLoadingData cancels the dataLoading promise
         * The actual Promise is not actually aborted, but when it resolved or rejected the result is disregarded
         */
        stopLoadingData = () => {
            // eslint-disable-next-line no-underscore-dangle
            if (this._cancelable) {
                // eslint-disable-next-line no-underscore-dangle
                this._cancelable.cancel();
            }
        };
        render() {
            const { LoadingComponent, ErrorComponent, alwaysShowLoadingComponent = false, alwaysShowErrorComponent = true, name, } = options;
            const dataLoaderProps = {
                ...this.props,
                ...this.state.data,
                forceFetch: this.forceFetch,
                onCancel: this.onCancel,
            };
            if (name) {
                // If the dataLoader is named, then the error and isLoading state is also named,
                // This is useful when you want multiple dataLoaders to fetch async, but don't want to wait for all before displaying the data
                if (this.props.isLoading && typeof this.props.isLoading === "object") {
                    dataLoaderProps.isLoading = {
                        ...this.props.isLoading,
                        [name]: this.state.isLoading,
                    };
                }
                else {
                    dataLoaderProps.isLoading = { [name]: this.state.isLoading };
                }
                if (this.props.startLoadTime && typeof this.props.startLoadTime === "object") {
                    dataLoaderProps.startLoadTime = {
                        ...this.props.startLoadTime,
                        [name]: this.state.startLoadTime,
                    };
                }
                else {
                    dataLoaderProps.startLoadTime = { [name]: this.state.startLoadTime };
                }
                if (this.props.error && typeof this.props.error === "object") {
                    dataLoaderProps.error = { ...this.props.error, [name]: this.state.error };
                }
                else {
                    dataLoaderProps.error = { [name]: this.state.error };
                }
            }
            else {
                Object.keys(omit(this.state, ["data"])).forEach((key) => {
                    dataLoaderProps[key] = this.state[key];
                });
            }
            if (this.state.isLoading && LoadingComponent && (alwaysShowLoadingComponent || !this.state.data)) {
                return <LoadingComponent {...dataLoaderProps}/>;
            }
            if (this.state.error && ErrorComponent && (alwaysShowErrorComponent || !this.state.data)) {
                return <ErrorComponent {...dataLoaderProps}/>;
            }
            return <Component {...dataLoaderProps}/>;
        }
    }
    return WithDataLoader;
};
/**
 * setDefaults returns a withDataLoader HOC with defaultOptions sat.
 * @param defaultOptions
 */
export const setDefaultOptions = (defaultOptions) => (dataLoader, options = {}) => {
    const propsToSet = { ...defaultOptions, ...options };
    return withDataLoader(dataLoader, propsToSet);
};
/**
 * combineDataLoaders is a helper function for withDataLoader
 * It takes a list of dataLoaders as parameters, returning one dataLoader which executes the functions async, which resolves with a merged result
 *
 * f1 => resolve({ a: 1 })
 * f2 => resolve({ b: 2 })
 * result => resolve({ a: 1, b 2 })
 *
 * @throws Error if any keys in the result are not unique, meaning the two dataLoaders have resolved with the same keys
 * @param dataLoaders A list of dataLoaders spread out as arguments
 */
export const combineDataLoaders = (...dataLoaders) => (props) => Promise.all(dataLoaders.map((dataLoader) => dataLoader(props))).then((values) => {
    const keys = flatten(values.map((obj) => Object.keys(obj)));
    if (uniq(keys).length !== keys.length) {
        throw new Error(`Dataloader keys are not unique: ${values.map((obj) => Object.keys(obj)).join(",")}`);
    }
    return values.reduce((a, b) => Object.assign(a, b), {});
});
export default withDataLoader;
