import React, { useState } from "react";
import moment from "moment";
import { useInterval } from "react-use";
import Alert from "@mui/material/Alert";
import Box from "@mui/material/Box";
import { useTheme } from "@mui/material/styles";
const SlowWarningComp = (props) => {
    const theme = useTheme();
    const [durationInMs, setDurationInMs] = useState(0);
    useInterval(() => {
        setDurationInMs(moment(new Date()).diff(props.startTime));
    }, 1000);
    if (durationInMs < props.timeLimitInMs)
        return null;
    return (<Box sx={{ p: theme.spacing(2) }}>
      <Alert elevation={6} variant="standard" severity="warning" sx={{ color: theme.palette.text.primary, backgroundColor: theme.palette.common.white }}>
        {props.warningText}
      </Alert>
    </Box>);
};
export default SlowWarningComp;
