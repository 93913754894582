import * as React from "react";
import { useState } from "react";
import { wrapDisplayName } from "recompose";
import Button from "@mui/material/Button";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mnemonic/mui5/Dialog";
import DialogContent from "@mnemonic/mui5/DialogContent";
import TechnicalInformation from "./TechnicalInformation";
const TechnicalInformationDialog = ({ open, onClose, aggregatedError, title }) => {
    const [copy, setCopy] = useState(0);
    return (<Dialog translate={"no"} {...{ open, onClose }} maxWidth="md">
      <DialogTitle>Technical information</DialogTitle>
      <DialogContent translate={"no"}>
        <TechnicalInformation {...{ aggregatedError, title, copy }}/>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Close</Button>
        <Button color="secondary" onClick={() => setCopy(copy + 1)}>
          Copy to clipboard
        </Button>
      </DialogActions>
    </Dialog>);
};
export const withTechnicalInformationDialog = () => (BaseComponent) => {
    class WrapperComponent extends React.Component {
        constructor(props) {
            super(props);
            this.state = { open: false };
        }
        onOpen = () => {
            this.setState({ open: true });
        };
        onClose = () => {
            this.setState({ open: false });
        };
        render() {
            return [
                <TechnicalInformationDialog key="info-dialog" 
                // @ts-ignore
                open={this.state.open} onClose={this.onClose} 
                // @ts-ignore
                title={this.props.title} 
                // @ts-ignore
                aggregatedError={this.props.aggregatedError}/>,
                <BaseComponent key="component" onOpenInfoDialog={this.onOpen} {...this.props}/>,
            ];
        }
    }
    // @ts-ignore
    WrapperComponent.displayName = wrapDisplayName(BaseComponent, "withTechnicalInformationDialog");
    return WrapperComponent;
};
export default TechnicalInformationDialog;
