import { authenticationRequired, permissionRequiredRender } from "@mnemonic/router";
import PageNotFound from "../Components/Navigation/PageNotFound";
import NoAccessPage from "../Pages/NoAccessPage";
import SearchPage from "../Pages/SearchPage";
import { logRoute } from "./interceptors";
const argusInterceptors = [
    logRoute(),
    authenticationRequired(),
    permissionRequiredRender("showPassiveDNSPage", NoAccessPage),
];
export const getRouteDefinitions = ({ isEnvironmentArgus, }) => [
    {
        path: "",
        component: SearchPage,
        interceptors: isEnvironmentArgus ? argusInterceptors : [logRoute()],
    },
    {
        path: "search",
        component: SearchPage,
        interceptors: isEnvironmentArgus ? argusInterceptors : [logRoute()],
    },
    {
        path: "search/:query",
        component: SearchPage,
        interceptors: isEnvironmentArgus ? argusInterceptors : [logRoute()],
    },
    {
        path: "*",
        component: PageNotFound,
        interceptors: [logRoute()],
    },
];
