/* eslint-disable react/prop-types */
import React from "react";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import GreyConsequenceIcon from "../../../Images/grey_Consequence.svg";
import ErrorTemplate from "./ErrorTemplate";
export const paperComponent = (BaseComponent) => ({ classes, ...props }) => (<Paper sx={(theme) => ({
        padding: theme.spacing(2),
        paddingTop: theme.spacing(3),
        paddingBottom: theme.spacing(3),
        display: "inline-block",
    })}>
        <BaseComponent {...props}/>
      </Paper>);
export const errorPage = (BaseComponent) => ({ classes, ...props }) => (<Box height="100%" width="100%" display="flex" justifyContent="center" flexDirection="row" alignItems="start" sx={{ mt: (theme) => theme.spacing(16) }}>
        <BaseComponent {...props}/>
      </Box>);
const GenericErrorComponent = ({ contactMail, error }) => (<ErrorTemplate title="Something went wrong" icon={GreyConsequenceIcon} refresh {...{ contactMail, error }}>
    Try to refresh the page, it might be a temporary problem
    <br />
    If the problem persists, please contact {contactMail}
  </ErrorTemplate>);
GenericErrorComponent.defaultProps = { contactMail: "mss@mnemonic.no" };
const GenericErrorPaper = paperComponent(GenericErrorComponent);
const GenericErrorPageWithPaper = errorPage(GenericErrorPaper);
export default GenericErrorPageWithPaper;
