/**
 * Adds a record to the history
 *
 * The record is added to the front of the list. Any duplicate record is removed.
 *
 * @param newRecord the record to add
 * @param history   the existing list of records, in historic order
 */
export const bubbleAdd = (newRecord, history) => {
    return history
        .filterNot(({ id }) => {
        return id === newRecord.id;
    })
        .unshift(newRecord);
};
