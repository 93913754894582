import React, { useContext, useState } from "react";
import { AutocompleteOption } from "@mnemonic/mui5";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import CircularProgress from "@mui/material/CircularProgress";
import TextField from "@mui/material/TextField";
import { FormattedMessage } from "@mne-core/intl";
import { SessionContext } from "@mne-authentication/session2";
const filterOptions = createFilterOptions({
    stringify: (customer) => `${customer.name} ${customer.shortName}`,
});
function CustomersAutocomplete({ TextFieldProps, error, loading, onChange, options, value, }) {
    const [isOptionsMenuOpen, setOptionsMenuOpen] = useState(false);
    const { session } = useContext(SessionContext);
    const { domain } = session.user;
    const stringify = (item) => item.name;
    return (<Autocomplete filterOptions={filterOptions} getOptionLabel={stringify} id="customer" loading={loading} onChange={(_, newValue) => onChange(newValue)} onClose={() => setOptionsMenuOpen(false)} onOpen={() => setOptionsMenuOpen(true)} open={isOptionsMenuOpen} options={options} value={value} renderOption={(props, options) => (<AutocompleteOption {...props} sx={{ overflow: "visible" }} title={options.name} subTitle={[options.domain?.name !== domain.name ? options.domain?.name : undefined, options?.shortName]
                .filter(Boolean)
                .join("/")} key={options.id}/>)} renderInput={(renderProps) => (<TextField {...renderProps} variant="standard" error={Boolean(error)} label={<FormattedMessage id="common.customer" defaultMessage="Customer"/>} helperText={error || undefined} inputProps={{
                ...renderProps.inputProps,
                autoComplete: "off",
            }} sx={{ minWidth: "300px", mt: (theme) => theme.spacing(2) }} 
        // eslint-disable-next-line
        InputProps={{
                ...renderProps.InputProps,
                endAdornment: (<React.Fragment>
                {loading ? <CircularProgress color="inherit" size={20}/> : null}
                {renderProps.InputProps.endAdornment}
              </React.Fragment>),
            }} fullWidth {...TextFieldProps}/>)} autoHighlight multiple/>);
}
export default CustomersAutocomplete;
