/* eslint-env browser */
// eslint-disable-next-line no-unused-vars,@typescript-eslint/no-unused-vars
import * as React from "react";
import { compose, lifecycle, withProps, withPropsOnChange } from "recompose";
import { mapStackTrace } from "sourcemapped-stacktrace";
const withAggregatedError = () => compose(
// We don't want it to crash the error handler when someone forgot to pass an error
withPropsOnChange(["error"], ({ error }) => {
    if (!(error instanceof Error)) {
        const placeholderError = new TypeError("No error given to debug component");
        console.error(placeholderError);
        return { error: placeholderError };
    }
    return {};
}), 
// Populate extra debug information
withPropsOnChange(["error"], ({ error }) => {
    let errorStackWithoutHeader;
    if (!error.stack) {
        errorStackWithoutHeader = "No stack available";
    }
    else {
        errorStackWithoutHeader = error.stack.split("\n").slice(1).join("\n");
    }
    return {
        url: window.location.href,
        errorStackWithoutHeader,
        environment: navigator.userAgent,
        timestamp: new Date().toISOString(),
    };
}), lifecycle({
    // Sourcemap the stacktrace
    componentDidMount() {
        if (this.props.error && this.props.error.stack) {
            mapStackTrace(this.props.error.stack, (mappedStack) => {
                const mappedErrorStack = mappedStack.join("\n");
                this.setState({ mappedErrorStack });
            });
        }
    },
}), 
// Wrap into one nice object
withProps(({ error, info, url, errorStackWithoutHeader, environment, timestamp, mappedErrorStack, extra }) => ({
    aggregatedError: {
        error,
        timestamp,
        info,
        url,
        errorStackWithoutHeader,
        environment,
        mappedErrorStack,
        extra,
    },
})));
export default withAggregatedError;
