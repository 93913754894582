import * as React from "react";
import PropTypes from "prop-types";
import { DEFAULT_ERROR_KEY, ErrorBoundary } from "./ErrorBoundary";
import GenericErrorPageWithPaper from "./ErrorView/GenericError";
const TopLevelErrorBoundary = ({ children, ...rest }) => (<ErrorBoundary {...rest} handlers={{
        [DEFAULT_ERROR_KEY]: { Component: GenericErrorPageWithPaper },
    }}>
    {children}
  </ErrorBoundary>);
TopLevelErrorBoundary.propTypes = {
    children: PropTypes.node.isRequired,
};
export default TopLevelErrorBoundary;
