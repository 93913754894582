/**
 * Material UI deprecated the onEnterKeyDown for TextFields, and instead require us to check whether the user
 * hit Enter. This function can be passed to wrap an onKeyPress or onKeyDown function to only trigger if the
 * Enter key was hit.
 *
 * onKeyDown={ onEnterKeyDown((e) => { // Do something }) }
 * @param fn
 */
const onEnterKeyDown = (fn) => (event) => {
    if (event.keyCode === 13 || event.which === "Enter") {
        return fn(event.target.value);
    }
    else {
        return () => null;
    }
};
export { onEnterKeyDown };
export default onEnterKeyDown;
