import React, { useEffect, useState } from "react";
import { Box } from "@mui/material";
import Snackbar from "@mui/material/Snackbar";
import Typography from "@mui/material/Typography";
function TechnicalInformation({ aggregatedError, title, copy }) {
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState("");
    const [snackbarDuration, setSnackbarDuration] = useState(1000);
    useEffect(() => {
        if (copy) {
            window.getSelection().selectAllChildren(document.getElementById("mne-techincal-information-content"));
            const res = document.execCommand("copy");
            if (res) {
                window.getSelection().removeAllRanges();
                setSnackbarMessage("Copied to clipboard");
                setSnackbarOpen(true);
            }
            else {
                let copyShortcut = "Ctrl+C";
                if (navigator.appVersion.indexOf("Mac")) {
                    copyShortcut = "⌘+C";
                }
                setSnackbarDuration(6000);
                setSnackbarMessage(`Press ${copyShortcut} to copy!`);
                setSnackbarOpen(true);
            }
        }
    }, [copy]);
    return (<>
      <Snackbar key="snackbar" open={snackbarOpen} onClose={() => setSnackbarOpen(false)} message={snackbarMessage} autoHideDuration={snackbarDuration}/>
      <Box key="content" sx={{ overflowX: "scroll" }} id="mne-techincal-information-content">
        {/* The current page url */}
        <Typography variant="body2">URL</Typography>
        <pre style={{
            fontFamily: `"Roboto Mono", monospace`,
            fontSize: 12,
            marginBottom: 12,
            marginTop: 0,
            whiteSpace: "nowrap",
        }}>
          {aggregatedError.url}
        </pre>

        {/* Environment, the user-agent string */}
        <Typography variant="body2">Environment</Typography>
        <pre style={{
            fontFamily: `"Roboto Mono", monospace`,
            fontSize: 12,
            marginBottom: 12,
            marginTop: 0,
            whiteSpace: "nowrap",
        }}>
          {aggregatedError.environment}
        </pre>

        {/* Timestamp when component mounted, iso formatted  */}
        <Typography variant="body2">Timestamp</Typography>
        <pre style={{
            fontFamily: `"Roboto Mono", monospace`,
            fontSize: 12,
            marginBottom: 12,
            marginTop: 0,
            whiteSpace: "nowrap",
        }}>
          {aggregatedError.timestamp}
        </pre>

        {/* Error title */}
        <Typography variant="body2">{title}</Typography>
        {/* Error message */}
        <Typography variant="body2">
          {aggregatedError.error.name}: {aggregatedError.error.message}
        </Typography>
        {/* Stacktrace */}
        <pre style={{
            fontFamily: `"Roboto Mono", monospace`,
            fontSize: 12,
            marginBottom: 12,
            marginTop: 0,
        }}>
          {aggregatedError.mappedErrorStack || aggregatedError.errorStackWithoutHeader}
        </pre>
      </Box>
    </>);
}
export default TechnicalInformation;
