import React, { useState } from "react";
import ClearIcon from "@mui/icons-material/Clear";
import NavigationIcon from "@mui/icons-material/Navigation";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import ListSubheader from "@mui/material/ListSubheader";
import MenuItem from "@mui/material/MenuItem";
import { useTheme } from "@mui/material/styles";
import { FormattedRelativeTime, defineMessages, useIntl } from "@mne-core/intl";
import isEnvironmentArgus from "../../Helpers/isEnvironmentArgus";
export const customerNames = (record) => {
    if (!record)
        return "";
    return record
        .getIn(["criteria", "customers"])
        .map((customer) => customer?.name || "")
        .sortBy((name) => name.toLowerCase())
        .join(", ");
};
const messages = defineMessages({
    history: { id: "searchPage.history", defaultMessage: "History" },
    delete: { id: "searchPage.delete", defaultMessage: "delete" },
});
/**
 * Displays a Drawer (sidebar) docked to the right of the screen, unless there's no history to browse through, in which
 * case this component returns null and displays nothing at all.
 */
export const SearchHistoryComp = (args) => {
    const theme = useTheme();
    const intl = useIntl();
    const [open, setOpen] = useState(true);
    const { history, currentIndex = 0, onSelect = () => null, onDelete = () => null } = args;
    if (history.count() < 1) {
        return null;
    }
    const historyStr = intl.formatMessage(messages.history);
    return (<div>
      <IconButton onClick={() => setOpen(!open)} style={{ float: "right", color: "white" }} size="large">
        <NavigationIcon />
      </IconButton>
      <Drawer sx={{
            ".MuiDrawer-paper": {
                background: theme.palette.primary.main,
                borderLeftWidth: "10px",
                borderLeftStyle: "solid",
                borderLeftColor: theme.palette.secondary.main,
                color: theme.palette.primary.contrastText,
                width: theme.spacing(33),
                zIndex: theme.zIndex.appBar - 1,
                paddingTop: isEnvironmentArgus() && theme.spacing(8),
            },
        }} open={open} anchor="right" variant="persistent">
        {screen.width < 1000 ? (<AppBar title={historyStr}/>) : (<ListSubheader color="inherit" sx={{ color: "#FFFFFF", background: theme.palette.primary.main }}>
            {historyStr}
          </ListSubheader>)}
        {history.map((search, index) => {
            const selected = index === currentIndex;
            const color = theme.palette[selected ? "secondary" : "primary"].contrastText;
            return (<MenuItem key={search.get("id")} selected={selected} sx={{
                    color: theme.palette.primary.contrastText,
                    padding: theme.spacing(1.5, 2),
                    backgroundColor: selected && `${theme.palette.secondary.main} !important`,
                }} id={index.toString()} onClick={() => onSelect(index)}>
              <Box flexDirection="column" justifyContent="space-around">
                <Box flex={0.5}>
                  <Box sx={{ color }}>{search.getIn(["criteria", "query"])}</Box>
                </Box>
                <Box sx={{ color, fontSize: "x-small", marginTop: "-0.5em" }}>
                  <FormattedRelativeTime value={new Date(search.get("timestamp") * 1000)} updateIntervalInSeconds={5}/>
                </Box>
                <Box flex={0.5} sx={{ fontSize: "x-small", whiteSpace: "normal", color: theme.palette.primary.light }}>
                  {customerNames(search)}
                </Box>
                <IconButton color="primary" aria-label={intl.formatMessage(messages.delete)} sx={{
                    color,
                    height: 10,
                    position: "absolute",
                    right: 10,
                    top: 10,
                    width: 10,
                    ".MuiIconButton-colorPrimary": {
                        color: theme.palette.secondary.contrastText,
                        fontWeight: "bold",
                        fontFamily: "verdana",
                    },
                }} onClick={(event) => {
                    event?.stopPropagation(); // Avoid triggering the MenuItem clickHandler
                    onDelete(index);
                }} size="large">
                  <ClearIcon />
                </IconButton>
              </Box>
            </MenuItem>);
        })}
      </Drawer>
    </div>);
};
export default SearchHistoryComp;
