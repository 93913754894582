/* eslint-env browser */
import * as React from "react";
import CssBaseline from "@mnemonic/mui5/CssBaseline";
import { createDefaultTheme, createNfcertTheme } from "@mnemonic/mui5/styles";
import { StyledEngineProvider, ThemeProvider } from "@mui/material/styles";
import { IntlProvider } from "@mne-core/intl";
import { getTargetMeta } from "@mne-core/providers";
import InnerErrorBoundary from "./../Components/ErrorBoundaries/InnerErrorBoundary";
import TopLevelErrorBoundary from "./../Components/ErrorBoundaries/TopLevelErrorBoundary";
import en from "./../i18n/en-US.json";
const AppWrapperWithoutSession = ({ children }) => (<TopLevelErrorBoundary>
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={getTargetMeta()?.metaInfo?.theme === "nfcert" ? createNfcertTheme() : createDefaultTheme()}>
        <CssBaseline />
        <IntlProvider locale="en-US" messages={en}>
          <InnerErrorBoundary>{children}</InnerErrorBoundary>
        </IntlProvider>
      </ThemeProvider>
    </StyledEngineProvider>
  </TopLevelErrorBoundary>);
export default AppWrapperWithoutSession;
