import React from "react";
import { amber, green, red } from "@mnemonic/mui5/colors";
import { List, Stack } from "immutable";
import takeWhile from "lodash/takeWhile";
import toPairs from "lodash/toPairs";
import moment from "moment";
import { useParams } from "react-router-dom";
import { useWindowSize } from "react-use";
import { compose, withHandlers, withProps, withStateHandlers } from "recompose";
import { Box } from "@mui/material";
import LinearProgress from "@mui/material/LinearProgress";
import Snackbar from "@mui/material/Snackbar";
import Tooltip from "@mui/material/Tooltip";
import { QueryController } from "@mne-core/api";
import { defineMessages, useIntl } from "@mne-core/intl";
import { getApiUrl } from "@mne-core/providers";
import { withSession } from "@mne-authentication/session2";
import { searchCustomersByCriteria } from "@mne-customer/api";
import searchByCriteria from "../Api/pdns";
import EmptyResult from "../Components/EmptyResult";
import SearchForm from "../Components/Forms/SearchForm";
import HeaderLogo from "../Components/Navigation/Logo";
import SearchHistory from "../Components/Navigation/SearchHistory";
import SlowWarningComp from "../Components/SlowWarning";
import ResultSet from "../Components/Tables/ResultSet";
import { onAjaxError } from "../Helpers/ErrorHandling";
import { bubbleAdd } from "../Helpers/History";
import { omitFieldsFor } from "../Helpers/Permissions";
import { isValidQuery } from "../Helpers/Validation";
import { requestRecordToSearchCriteria } from "../Helpers/domain";
import isEnvironmentArgus from "../Helpers/isEnvironmentArgus";
import HistoryRecord, { createId } from "../Immutables/HistoryRecord";
import RequestRecord from "../Immutables/RequestRecord";
import withDataLoader from "../withDataLoader";
const createStyles = () => ({
    // Consider making the search history part of the content flow to avoid margin hacks like this.
    // TLP Colors
    white: {
        backgroundColor: "#EFEFEF",
    },
    amber: {
        backgroundColor: amber.A700,
    },
    yellow: {
        backgroundColor: "#EEEE00",
    },
    green: {
        backgroundColor: green.A700,
    },
    red: {
        backgroundColor: red.A700,
    },
    currentPageLink: {
        float: "right",
        marginRight: 256,
    },
});
const messages = defineMessages({
    columnAnswer: { id: "searchPage.column.answer", defaultMessage: "Answer" },
    columnFirstSeen: { id: "searchPage.column.firstSeen", defaultMessage: "First seen" },
    columnLastSeen: { id: "searchPage.column.lastSeen", defaultMessage: "Last seen" },
    columnCustomer: { id: "searchPage.column.customer", defaultMessage: "Customer" },
    columnTimesSeenLabel: { id: "searchPage.column.timesSeen.label", defaultMessage: "# times" },
    columnTimesSeenTooltip: { id: "searchPage.column.timesSeen.tooltip", defaultMessage: "Times seen" },
    columnTtl: { id: "searchPage.column.ttl", defaultMessage: "TTL" },
    columnTlp: { id: "searchPage.column.tlp", defaultMessage: "TLP" },
    columnRecordType: { id: "searchPage.column.recordType", defaultMessage: "Record type" },
    columnQueryLabel: { id: "searchPage.column.query.label", defaultMessage: "Query" },
    columnQueryTooltip: { id: "searchPage.column.query.tooltip", defaultMessage: "DNS Query" },
});
// Columns we can ACCEPT not showing if the window width is too small:
const LEAST_IMPORTANT_COLUMNS = ["maxTtl", "times", "customer", "tlp", "rrtype", "firstSeenTimestamp"];
export const pageWithSameStartingData = (props) => {
    if (!props.newLimit || props.newLimit < 1)
        return 0;
    const indexOfFirstItemOnOldPage = props.oldPage * props.oldLimit;
    return Math.floor(indexOfFirstItemOnOldPage / props.newLimit);
};
export const removeFromHistory = (currentState) => (indexToRemove) => {
    const { history, index } = currentState;
    const historyWithoutItem = history.remove(indexToRemove);
    let newIndex = 0;
    if (indexToRemove < index) {
        newIndex = index - 1;
    }
    else if (indexToRemove > index) {
        newIndex = index;
    }
    return {
        history: historyWithoutItem,
        index: newIndex,
        criteria: historyWithoutItem.has(newIndex) ? historyWithoutItem.get(newIndex).criteria : new RequestRecord(),
    };
};
/**
 * SearchPage which displays SearchForm at the top, SearchHistory on the right side, and
 * the ResultSet table on the bottom.
 */
export const SearchPageComp = ({ criteria = new RequestRecord(), notifications = Stack(), history = List(), index = 0, incrementQueryId, count = 0, page = 1, searchInputValidationError, isLoading = {}, startLoadTime = {}, canViewCustomers = false, setHistoryIndex, setPage, setQuery, shiftNotifications, removeFromHistory, allCustomers = [], }) => {
    const classes = createStyles();
    const intl = useIntl();
    const { width } = useWindowSize();
    const isLoadingDueToSearch = isLoading.searchResultLoader && page === 0;
    const isLoadingDueToPagination = isLoading.searchResultLoader && page > 0;
    const showEmptyResult = !isLoading.searchResultLoader && history.get(index)?.results.isEmpty();
    const showResultTable = history.get(index)?.results && !history.get(index).results.isEmpty();
    return (<Box sx={{ mb: ({ spacing }) => spacing(2) }}>
      <Box sx={({ palette }) => isEnvironmentArgus()
            ? { backgroundColor: palette.background.default, color: palette.secondary.light }
            : { backgroundColor: palette.primary.dark }}>
        <SearchHistory history={history} currentIndex={index} onDelete={removeFromHistory} onSelect={setHistoryIndex}/>
        <HeaderLogo />

        <Box sx={{ mr: "250px" }}>
          <SearchForm allCustomers={allCustomers} allowUserSearch={Boolean(canViewCustomers)} criteria={criteria} error={Boolean(searchInputValidationError)} helperText={searchInputValidationError} onChange={setQuery} onEnter={() => setPage(0) || incrementQueryId()}/>
          {isLoading.searchResultLoader && (<SlowWarningComp timeLimitInMs={10000} startTime={startLoadTime.searchResultLoader} warningText="Still waiting for results. Certain searches may take up to several minutes."/>)}
        </Box>
      </Box>

      <LinearProgress variant="indeterminate" style={{ visibility: isLoadingDueToSearch ? "visible" : "hidden" }} color="secondary" value={100}/>
      <Box sx={{ mr: "250px" }}>
        {showEmptyResult && <EmptyResult />}
        {showResultTable && (<ResultSet columns={List(takeWhile(omitFieldsFor(canViewCustomers)([
                {
                    label: intl.formatMessage(messages.columnRecordType),
                    tooltip: intl.formatMessage(messages.columnRecordType),
                    field: "rrtype",
                },
                {
                    label: intl.formatMessage(messages.columnQueryLabel),
                    tooltip: intl.formatMessage(messages.columnQueryTooltip),
                    field: "query",
                    formatter: (value) => (<Tooltip placement="bottom" title={value}>
                        <span className="link-cell">{value}</span>
                      </Tooltip>),
                    onClick: (row) => setQuery({ query: row.query }) || incrementQueryId(),
                },
                {
                    label: intl.formatMessage(messages.columnAnswer),
                    tooltip: intl.formatMessage(messages.columnAnswer),
                    field: "answer",
                    formatter: (value) => (<Tooltip placement="bottom" title={value}>
                        <span className="link-cell">{value}</span>
                      </Tooltip>),
                    onClick: (row) => setQuery({ query: row.answer }) || incrementQueryId(),
                },
                {
                    label: intl.formatMessage(messages.columnFirstSeen),
                    tooltip: intl.formatMessage(messages.columnFirstSeen),
                    field: "firstSeenTimestamp",
                    formatter: (value) => (<Tooltip placement="bottom" title={moment(value).format("YYYY-MM-DD HH:mm")}>
                        <span>{moment(value).format("YYYY-MM-DD HH:mm")}</span>
                      </Tooltip>),
                },
                {
                    label: intl.formatMessage(messages.columnLastSeen),
                    tooltip: intl.formatMessage(messages.columnLastSeen),
                    sorting: {
                        active: true,
                        direction: "desc",
                    },
                    field: "lastSeenTimestamp",
                    formatter: (value) => (<Tooltip placement="bottom" title={moment(value).format("YYYY-MM-DD HH:mm")}>
                        <span>{moment(value).format("YYYY-MM-DD HH:mm")}</span>
                      </Tooltip>),
                },
                {
                    label: intl.formatMessage(messages.columnCustomer),
                    tooltip: intl.formatMessage(messages.columnCustomer),
                    field: "customer",
                    formatter: (value) => value ? (<Tooltip placement="bottom" title={value.name}>
                          <span>{value.name}</span>
                        </Tooltip>) : ("N/A"),
                },
                {
                    label: intl.formatMessage(messages.columnTimesSeenLabel),
                    tooltip: intl.formatMessage(messages.columnTimesSeenTooltip),
                    field: "times",
                    formatter: (value) => (<Tooltip placement="bottom" title={value}>
                        <span>{value}</span>
                      </Tooltip>),
                },
                {
                    label: intl.formatMessage(messages.columnTtl),
                    tooltip: intl.formatMessage(messages.columnTtl),
                    field: "maxTtl",
                },
                {
                    label: intl.formatMessage(messages.columnTlp),
                    tooltip: intl.formatMessage(messages.columnTlp),
                    field: "tlp",
                    formatter: (value) => (<Tooltip placement="bottom" title={value}>
                        <Box sx={{
                            borderRadius: 5,
                            height: 10,
                            width: 10,
                            border: "1px solid rgb(190, 201, 196)",
                            ...classes[value],
                        }}>
                          &nbsp;
                        </Box>
                      </Tooltip>),
                },
            ]), ({ field }, i) => {
                return !LEAST_IMPORTANT_COLUMNS.includes(field) || i * 210 < width;
            }))} results={history.get(index) ? history.get(index).results : List()} onPaginate={(args) => {
                setPage(args);
            }} currentPage={page} totalRecords={count} pageSize={criteria.limit} onChangePageSize={(limit) => {
                setQuery({ limit });
                const newPage = pageWithSameStartingData({ oldLimit: criteria.limit, newLimit: limit, oldPage: page });
                setPage(newPage);
            }}/>)}
      </Box>
      {isLoadingDueToPagination && <LinearProgress variant="indeterminate" color="secondary" value={100}/>}
      <Snackbar open={!notifications.isEmpty()} message={notifications.first() || ""} autoHideDuration={10000} onClose={shiftNotifications}/>
    </Box>);
};
export const SearchPageWithState = compose(withSession(), withProps(({ session }) => ({
    fetchContext: {
        apiUrl: getApiUrl(),
        sessionKey: session && session.info.sessionKey,
    },
})), withProps(({ session }) => ({
    canViewCustomers: Boolean(session && session.functions && session.functions.find(({ name }) => name === "viewCustomers")),
})), withStateHandlers((props) => {
    const initCriteria = props.initialQuery
        ? new RequestRecord().set("query", props.initialQuery)
        : new RequestRecord();
    return {
        criteria: initCriteria,
        queryId: 0,
        notifications: Stack(),
        history: List(),
        index: 0,
        page: 0,
        searchInputValidationError: null,
    };
}, {
    // Increment the query count (triggering a new search with the current criteria whether or not that criteria is loaded from history
    incrementQueryId: ({ queryId }) => () => ({
        queryId: queryId + 1,
    }),
    // Sets the current page
    setPage: ({ queryId }) => (page) => ({
        page,
        queryId: queryId + 1,
    }),
    // Set values on the current criteria RequestRecord
    setQuery: ({ criteria }) => (newValues) => ({
        criteria: toPairs(newValues).reduce((current, nextPair) => current.set(...nextPair), criteria || new RequestRecord()),
    }),
    // Replaces the current criteria request record
    replaceQuery: () => (newValues) => ({
        criteria: newValues,
    }),
    // Adds a notification
    addNotification: ({ notifications }) => (notification) => ({
        notifications: notifications.push(notification),
    }),
    // Pops the first notification
    shiftNotifications: ({ notifications }) => () => ({
        notifications: notifications.splice(0, 1),
    }),
    // Paginates through History, loading previous criteria and resultset
    setHistoryIndex: ({ history }) => (index) => ({
        index,
        criteria: history.has(index) ? history.get(index).criteria : new RequestRecord(),
    }),
    // Removes an item from history
    removeFromHistory,
    // Adds a criteria + resultset to history
    addToHistory: ({ history }) => ({ criteria, results, count }) => {
        return {
            history: bubbleAdd(new HistoryRecord({
                id: createId(criteria),
                criteria,
                results,
                count,
                timestamp: new Date().getTime() / 1000,
            }), history),
            index: 0,
        };
    },
    setSearchInputValidationError: () => (value) => ({
        searchInputValidationError: value,
    }),
}), withHandlers({
    errorHandler: ({ addNotification }) => onAjaxError({ addNotification }),
}), 
// Performing a search should always append to history before performing the search
withDataLoader(({ page, criteria, addToHistory, replaceQuery, errorHandler, fetchContext, setSearchInputValidationError, }) => {
    if (!criteria || !criteria.query || criteria.query.length === 0) {
        return Promise.resolve();
    }
    if (!isValidQuery(criteria.query)) {
        setSearchInputValidationError("Not a valid query, must be valid (partial) URI or IP");
        return Promise.reject();
    }
    else {
        setSearchInputValidationError(null);
    }
    return searchByCriteria(requestRecordToSearchCriteria(criteria, page), {
        apiUrl: fetchContext.apiUrl,
        ctrl: new QueryController({ timeout: 5 * 60 * 1000 }), // The backend timeout is set to 5 minutes
    })
        .then((query) => {
        if (query.response.ok !== true) {
            return Promise.reject(query);
        }
        const result = query.json;
        // Add this search to History
        addToHistory({
            criteria,
            page,
            results: List(result.data),
            count: result.count,
        });
        // Replace the current request record with the one used for the search if it changed in the meantime
        replaceQuery(criteria);
        return { ...result, currentPage: page, count: result.count };
    })
        .catch(errorHandler);
}, {
    name: "searchResultLoader",
    watchProps: ["queryId"],
    // Only reload data if the queryId has changed
    shouldLoadData: (props, nextProps) => {
        // @ts-ignore
        return props.queryId !== nextProps.queryId;
    },
}), withDataLoader(({ canViewCustomers, errorHandler }) => {
    if (!canViewCustomers) {
        return Promise.resolve({});
    }
    return searchCustomersByCriteria(
    // Just fetch all customers for now
    { limit: 1000, sortBy: "name" }, {
        apiUrl: getApiUrl(),
    })
        .then((query) => {
        if (query.response.ok !== true) {
            return Promise.reject(query);
        }
        return { allCustomers: query.json.data };
    })
        .catch(errorHandler);
}, {
    name: "customersLoader",
    // Just fetch once
    shouldLoadData: () => false,
}))(SearchPageComp);
const SearchPage = (props) => {
    const { query } = useParams();
    return <SearchPageWithState {...props} initialQuery={query || ""}/>;
};
export default SearchPage;
