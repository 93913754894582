import { createIntl, createIntlCache, defaultFormats, getDefaultBrowserTimeZone, getLocaleFromLanguage, setHour12, } from "@mne-core/intl";
import en from "../i18n/en-US.json";
import nb from "../i18n/nb-NO.json";
const cache = createIntlCache();
const messagesByLocale = {
    "en-US": { ...en },
    "nb-NO": { ...nb },
};
const defaultLocale = "en-US";
const intl = createIntl({
    locale: defaultLocale,
    messages: messagesByLocale[defaultLocale],
}, cache);
export default async function initializeIntl({ session }) {
    if (!session) {
        return intl;
    }
    const locale = getLocaleFromLanguage(session?.user?.language);
    const isHour12 = session.user.properties["time.hour12"] === "true";
    const formats = setHour12(defaultFormats, isHour12);
    const timeZone = getDefaultBrowserTimeZone();
    return createIntl({
        locale,
        messages: messagesByLocale[locale],
        defaultFormats: formats,
        timeZone,
    }, createIntlCache());
}
