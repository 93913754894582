import LogClient, { setupLogging } from "@mne-core/logging";
import { getApiUrl } from "@mne-core/providers";
const isLocal = () => {
    return window.location.hostname.startsWith("localhost");
};
export default async function initializeLogging({ session }) {
    const sessionKey = session?.info ? session.info.sessionKey : null;
    const logLevel = session?.user?.properties["logging.client.level"]
        ? session.user.properties["logging.client.level"]
        : "info";
    const logClient = LogClient.init({
        apiUrl: getApiUrl(),
        sessionKey,
        pageOrigin: __PACKAGE__NAME__,
        version: __PACKAGE__VERSION__,
        logLevel,
        logToConsole: NODE_ENV !== "production",
        logToApi: !isLocal(),
    });
    if (!isLocal()) {
        // Sets up some default logging. See https://stash.mnemonic.no/projects/JAV/repos/mne-core/browse/packages/logging/src/setupLogging.ts
        setupLogging(logClient);
    }
    return logClient;
}
