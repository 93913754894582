import React from "react";
import { useTheme } from "@mui/material";
import Typography from "@mui/material/Typography";
import { withTechnicalInformationDialog } from "./TechnicalInformationDialog";
const TechnicalInformationLinkComp = ({ onOpenInfoDialog }) => {
    const theme = useTheme();
    return (<Typography sx={{
            marginTop: theme.spacing(3),
            "& > a": {
                color: theme.palette.text.disabled,
                cursor: "pointer",
                textDecoration: "underline",
                "&:hover": {
                    color: theme.palette.text.secondary,
                },
                "&:focus,&:active": {
                    color: theme.palette.text.primary,
                },
                transition: theme.transitions.create("color", {
                    duration: theme.transitions.duration.shortest,
                }),
            },
        }}>
      <a onClick={onOpenInfoDialog} role="button" tabIndex={0}>
        Technical information
      </a>
    </Typography>);
};
export default withTechnicalInformationDialog()(TechnicalInformationLinkComp);
