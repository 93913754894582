import React from "react";
import Box from "@mui/material/Box";
import { FormattedMessage } from "@mne-core/intl";
const EmptyResult = () => (<Box sx={(theme) => ({
        textAlign: "center",
        fontSize: "4rem",
        fontFamily: "Roboto",
        marginTop: theme.spacing(4),
        color: theme.palette.primary.light,
    })}>
    <FormattedMessage id={"common.no-results"} defaultMessage="No results"/>
  </Box>);
export default EmptyResult;
