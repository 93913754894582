/* eslint-env browser */
import * as React from "react";
import { ArgusMenu, getMenuConfig, permissionCheckerUtil } from "@mnemonic/mui5";
import CssBaseline from "@mnemonic/mui5/CssBaseline";
import { createDefaultTheme, createNfcertTheme } from "@mnemonic/mui5/styles";
import { StyledEngineProvider, ThemeProvider } from "@mui/material/styles";
import { RawIntlProvider } from "@mne-core/intl";
import Logger from "@mne-core/logging";
import { getApiUrl, getMetaInfo } from "@mne-core/providers";
import { getTargetMeta } from "@mne-core/providers";
import InnerErrorBoundary from "./../Components/ErrorBoundaries/InnerErrorBoundary";
import TopLevelErrorBoundary from "./../Components/ErrorBoundaries/TopLevelErrorBoundary";
import ApplicationSession from "./ApplicationSession";
const AppWrapper = ({ intl, session, children }) => {
    const { domain } = getTargetMeta();
    return (<TopLevelErrorBoundary>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={getMetaInfo().theme === "nfcert" ? createNfcertTheme() : createDefaultTheme()}>
          <CssBaseline />
          <ApplicationSession session={session} apiUrl={getApiUrl()}>
            <RawIntlProvider value={intl}>
              <ArgusMenu {...{
        ...permissionCheckerUtil(session, getMenuConfig(domain, 
        // ARGUSUSER-6772 - Force disable the beta menu.
        // When removing beta code, replace with: ´session?.user`,
        {
            ...session?.user,
            properties: { ...session?.user?.properties, "argusmenu.beta": "false" },
        }, intl?.locale, Logger.getInstance())),
        logger: Logger.getInstance(),
    }}/>
              <InnerErrorBoundary>{children}</InnerErrorBoundary>
            </RawIntlProvider>
          </ApplicationSession>
        </ThemeProvider>
      </StyledEngineProvider>
    </TopLevelErrorBoundary>);
};
export default AppWrapper;
