/* eslint-disable @typescript-eslint/no-explicit-any */
import * as React from "react";
import { RemoveSplash } from "@mnemonic/mui5";
import { useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import LogClient from "@mne-core/logging";
export const DEFAULT_ERROR_KEY = "__default__";
export class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            Component: null,
            error: null,
            info: null,
        };
    }
    componentDidCatch(error, info) {
        LogClient.getInstance()?.logException(error, {
            componentStack: info.componentStack,
        });
        // Check if we should handle it
        let handler;
        Object.keys(this.props.handlers).forEach((k) => {
            if (handler)
                return;
            if (error.name === k) {
                handler = this.props.handlers[k];
            }
        });
        // If a default handler is sat use that
        if (!handler && Object.prototype.hasOwnProperty.call(this.props.handlers, DEFAULT_ERROR_KEY)) {
            handler = this.props.handlers[DEFAULT_ERROR_KEY];
        }
        // Not handle
        if (!handler)
            throw error;
        // Handle it
        const { Component, onError } = handler;
        if (onError) {
            onError.call(this, error, info);
        }
        this.setState({ error, info, Component });
    }
    render() {
        const { error, info, Component } = this.state;
        /* eslint-disable-next-line */
        const { children, ...restProps } = this.props;
        if (error && Component) {
            return (<Box sx={{ marginTop: (theme) => theme.spacing(1) }}>
          <RemoveSplash />
          <Component {...restProps} {...{ error, info }}/>
        </Box>);
        }
        return this.props.children;
    }
}
export const RouterAwareErrorBoundary = withHistoryHook(ErrorBoundary);
function withHistoryHook(Component) {
    return function WrappedComponent(props) {
        const navigate = useNavigate();
        return <Component {...props} history={navigate}/>;
    };
}
