import * as React from "react";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material/styles";
import GreyConsequenceIcon from "../../../Images/grey_Consequence.svg";
import TechnicalInformationLink from "./TechnicalInformationLink";
import withAggregatedError from "./withAggregatedError";
import { Box } from "@mui/material";
const ContactUsButton = ({ contactMail, aggregatedError, title }) => {
    const mailtoSubject = `Argus Error Report`;
    const mailtoBody = `
    %0D%0A%0D%0A
    Technical information below

    URL: ${encodeURIComponent(aggregatedError.url)}
    Environment: ${aggregatedError.environment}
    Timestamp: ${aggregatedError.timestamp}
    ${title}
    ${aggregatedError.error.name}: ${aggregatedError.error.message}
    ${aggregatedError.mappedErrorStack || aggregatedError.errorStackWithoutHeader}
   
  `
        .trim()
        .replace(/\n/g, "%0D%0A")
        .replace(/\s+/g, " ");
    return (<Button color="secondary" href={`mailto:${contactMail}?subject=${mailtoSubject}&body=${mailtoBody}`}>
      Contact us
    </Button>);
};
export const createStyle = (theme) => ({
    root: {
        textAlign: "center",
    },
    icon: {
        marginBottom: theme.spacing(3),
    },
    title: {
        marginBottom: theme.spacing(2),
    },
    text: {
        marginBottom: theme.spacing(2),
    },
});
const RefreshButton = () => (
// eslint-disable-next-line no-undef
<Button color="secondary" onClick={() => window.location.reload()}>
    Refresh
  </Button>);
const ErrorTemplateComp = ({ aggregatedError, title, children, icon, contactMail, refresh }) => {
    const theme = useTheme();
    const classes = createStyle(theme);
    return (<Box sx={classes.root}>
      {icon && <img style={classes.icon} src={icon} alt=""/>}

      <Typography variant="subtitle1" sx={classes.title}>
        {title}
      </Typography>
      <Typography variant="body1" color="textSecondary" sx={classes.text}>
        {children}
      </Typography>

      {refresh && <RefreshButton />}
      {contactMail && <ContactUsButton {...{ aggregatedError, contactMail, title }}/>}

      <TechnicalInformationLink {...{ aggregatedError, title }}/>
    </Box>);
};
ErrorTemplateComp.defaultProps = {
    icon: GreyConsequenceIcon,
    contactMail: "mss@mnemonic.no",
    refresh: false,
};
const ErrorTemplate = withAggregatedError()(ErrorTemplateComp);
export default ErrorTemplate;
