import React from "react";
import { List } from "immutable";
import Box from "@mui/material/Box";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import TextField from "@mui/material/TextField";
import { FormattedMessage, defineMessages, useIntl } from "@mne-core/intl";
import isEnvironmentArgus from "../../Helpers/isEnvironmentArgus";
import { onEnterKeyDown } from "../../Helpers/onEnterKeyDown";
import RequestRecord from "../../Immutables/RequestRecord";
import CustomerAutocomplete from "./CustomerAutocompleter";
const getTextFieldStyle = (theme) => isEnvironmentArgus()
    ? {
        color: "#222222",
        paddingLeft: theme.spacing(0),
        fontWeight: "normal",
        marginBottom: theme.spacing(0),
    }
    : { color: "#FFFFFF" };
const messages = defineMessages({
    searchPlaceHolder: {
        id: "search.placeholder",
        defaultMessage: "E.g mnemonic.no",
    },
});
const SearchForm = ({ criteria = new RequestRecord(), allowUserSearch = false, allCustomers = [], error, helperText, onChange, onEnter, }) => {
    const intl = useIntl();
    return (<Box width="60%" margin="auto auto" sx={{
            paddingTop: (theme) => theme.spacing(10),
            "@media screen and (max-width: 1400px)": { width: "90%" },
            "@media screen and (max-width: 1000px)": { width: "95%" },
        }}>
      <Box sx={{ display: "flex", alignItems: "flex-end" }}>
        <TextField FormHelperTextProps={{ sx: { color: "#999999" } }} InputLabelProps={{ sx: (theme) => getTextFieldStyle(theme) }} InputProps={{ sx: (theme) => getTextFieldStyle(theme) }} autoFocus error={error} fullWidth helperText={helperText} id="search" label={<FormattedMessage id="search.label" defaultMessage="Search ..."/>} onChange={({ target: { value } }) => onChange({ query: value, offset: 0 })} onKeyDown={onEnterKeyDown(onEnter)} placeholder={criteria.query ? undefined : intl.formatMessage(messages.searchPlaceHolder)} value={criteria.query} variant="standard"/>
        {allowUserSearch && (<Box>
            <CustomerAutocomplete options={allCustomers} onChange={(customers) => customers
                ? onChange({
                    customerID: customers.map(({ id }) => id),
                    customers: List(customers),
                    tlp: [],
                }) || onEnter()
                : onChange({
                    customerID: List(),
                    customers: List(),
                    tlp: [],
                })}/>
          </Box>)}
      </Box>

      {/* <div style={{ clear: "both" }} /> */}
      {isEnvironmentArgus() && (<>
          <FormControlLabel label={<FormattedMessage id="filter.includeAnon" defaultMessage="Include anonymous"/>} control={<Checkbox color="secondary" checked={criteria.includeAnonymousResults} onChange={(e) => onChange({ includeAnonymousResults: e.target.checked })}/>}/>
          <FormControlLabel label={<FormattedMessage id="filter.aggregateResults" defaultMessage="Aggregate results"/>} control={<Checkbox color="secondary" checked={criteria.aggregateResult} onChange={(e) => onChange({ aggregateResult: e.target.checked })}/>}/>
        </>)}
    </Box>);
};
export default SearchForm;
