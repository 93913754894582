import { getTargetMeta } from "@mne-core/providers";
import * as CurrentuserApi from "@mne-authentication/api/currentuser";
import HttpError from "./Errors/HttpError";
export default async function initializeSession() {
    const { domain, apiUrl } = getTargetMeta();
    const query = await CurrentuserApi.fetchSession({ domain }, { apiUrl });
    if (query.response.ok) {
        return query.json;
    }
    else if (query.response.status === 401) {
        return null;
    }
    throw new HttpError(query.response.statusText, query);
}
