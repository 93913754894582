export const requestRecordToSearchCriteria = (criteria, page) => {
    const criteriaJs = criteria.toJS();
    return Object.assign({
        offset: page * criteriaJs.limit || 0,
        limit: criteriaJs.limit,
        query: criteriaJs.query,
        tlp: criteriaJs.tlp,
        aggregateResult: criteriaJs.aggregateResult,
        includeAnonymousResults: criteriaJs.includeAnonymousResults,
        rrClass: criteriaJs.rrClass,
        rrType: criteriaJs.rrType,
    }, criteriaJs.customerID && criteriaJs.customerID.length > 0 && { customerID: criteriaJs.customerID });
};
