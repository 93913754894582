import LogClient from "@mne-core/logging";
export const hasMessages = (error) => error.json && error.json.messages && error.json.messages.length > 0;
export const hasStatusCode = (error) => error.response && error.response.status;
export const HTTPErrors = {
    401: "User is not logged in",
    403: "Forbidden",
    404: "Nothing found",
    412: "Malformed request - this shouldn't happen",
    500: "Something went wrong",
};
export const DEFAULT_ERROR_MESSAGE = "Something went wrong with the request";
const errorMessages = (error) => {
    const messages = error?.json?.messages?.map((m) => m?.message) || [];
    return [
        "Failed to fetch",
        error?.request?.url,
        error?.response?.status,
        error?.message,
        error?.title,
        ...messages,
    ].filter(Boolean);
};
export const onAjaxError = ({ addNotification }) => (error) => {
    LogClient.getInstance().logError(errorMessages(error).join(","));
    if (!error) {
        return addNotification(DEFAULT_ERROR_MESSAGE);
    }
    else if (hasMessages(error)) {
        return error.json.messages.forEach((m) => addNotification(m.message));
    }
    else if (hasStatusCode(error) && HTTPErrors[error.response.status]) {
        return addNotification(HTTPErrors[error.response.status]);
    }
    else if (hasStatusCode(error)) {
        return addNotification(`${error.response.status}: Something went wrong with the request`);
    }
    else if (error.title) {
        return addNotification(error.title);
    }
    else if (error.name === "RawQueryError" && error.ctrl && error.ctrl.hasTimedOut) {
        return addNotification(error.message);
    }
    else {
        return addNotification(DEFAULT_ERROR_MESSAGE);
    }
};
