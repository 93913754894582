import React from "react";
import Box from "@mui/material/Box";
import { useTheme } from "@mui/material/styles";
import isEnvironmentArgus from "../../Helpers/isEnvironmentArgus";
import SvgIcon from "../../Images/Icon_Dark_Passive_DNS-32.svg";
export const Logo = () => {
    const theme = useTheme();
    return (<Box sx={{
            fontFamily: "Roboto",
            fontSize: "5rem",
            fontStyle: "italic",
            fontWeight: 600,
            textAlign: "center",
            textTransform: "uppercase",
            color: isEnvironmentArgus() ? theme.palette.primary.light : theme.palette.grey[200],
            paddingTop: isEnvironmentArgus() && theme.spacing(8),
        }}>
      <Box sx={{ verticalAlign: "super", display: "inline-block" }}>Passive</Box>
      <img style={{ paddingTop: "1rem", verticalAlign: "sub", width: "17rem" }} src={SvgIcon} alt="Logo"/>
    </Box>);
};
export default Logo;
