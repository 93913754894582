import { List, Record } from "immutable";
import RequestRecord from "./RequestRecord";
export const createId = (criteria) => {
    const customers = criteria
        .get("customers", [])
        .map((customer) => `${customer.id}Domain:${customer.domain.id}`)
        .sort()
        .toJS();
    return `${criteria.get("query")} [${customers}]`;
};
class HistoryRecord extends Record({
    id: "1",
    criteria: new RequestRecord(),
    results: List(),
    offset: 0,
    pageSize: 25,
    count: 0,
    timestamp: new Date().getTime() / 1000,
}) {
}
export default HistoryRecord;
