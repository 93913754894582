import { QueryController, createUrl, fetchJsonQuery } from "@mne-core/api";
function createHeaders(opts) {
    const entries = Object.keys(opts)
        .filter((key) => Boolean(opts[key]))
        .reduce((obj, key) => ({ ...obj, [key]: opts[key] }), {});
    return new Headers(entries);
}
async function searchByCriteria(criteria, opts) {
    const { apiUrl, apikey, ctrl: optsCtrl, ...restOpts } = opts;
    const ctrl = optsCtrl || new QueryController();
    const url = createUrl(apiUrl, ["pdns", "v3", "search"]).toString();
    const headers = createHeaders({
        /* If the apikey is something else then undefined its added */
        "ARGUS-API-KEY": apikey,
        Accept: "application/json",
        "Argus-CSRF-Token": false,
        "Content-Type": "application/json",
    });
    let localRequestOpts = {
        body: JSON.stringify(criteria, null, 2),
        headers,
        method: "POST",
        mode: "cors",
    };
    if (!apikey) {
        /**
         * Only add cookies if no apikey is provided
         */
        localRequestOpts = {
            ...localRequestOpts,
            credentials: "include",
        };
    }
    return fetchJsonQuery(new Request(url, {
        ...localRequestOpts,
        ...restOpts,
    }), { ctrl });
}
export default searchByCriteria;
