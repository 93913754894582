/* eslint-disable you-dont-need-lodash-underscore/get */
import React from "react";
import { TablePagination } from "@mnemonic/mui5";
import { List } from "immutable";
import { get } from "lodash";
import PropTypes from "prop-types";
import { compose, onlyUpdateForKeys } from "recompose";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableFooter from "@mui/material/TableFooter";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Tooltip from "@mui/material/Tooltip";
import { FormattedMessage, defineMessages, useIntl } from "@mne-core/intl";
// Display N/A for unset fields
const FieldOrNa = (field) => field || "N/A";
const messages = defineMessages({
    next: { id: "common.next", defaultMessage: "Next" },
    previous: { id: "common.previous", defaultMessage: "Previous" },
});
const labelDisplayedRows = ({ from, to, count }) => {
    return (<span>
      {from}-{to} <FormattedMessage id="common.of" defaultMessage="of"/> {count}
    </span>);
};
/**
 * This is the table which displays the search results
 *
 * @param {Array}         columns               List of Table Columns to display
 * @param {function}      onSortField           Callback fired when user tries to sort
 * @param {List}          results               List of SearchResults, from SearchState.searches[i].results
 * @param {function}      onPaginate            Triggered when the user wants to paginate to another page. Receives page number.
 * @param {function}      currentPage           Currently displayed page
 * @param {function}      totalRecords          Number of records in result
 */
export const ResultSetComp = ({ columns, results, onPaginate, onChangePageSize, currentPage, pageSize, totalRecords, }) => {
    const { formatMessage } = useIntl();
    const cols = columns;
    return (<Table height={results.length < 1 ? "400px" : undefined}>
      <TableHead>
        <TableRow>
          {columns.map((field) => (<TableCell key={field.label}>
              {!field.tooltip ? (field.label) : (<Tooltip title={field.tooltip} placement="right">
                  {!field.sorting ? (<span>{field.label}</span>) : (<TableSortLabel style={{ cursor: "text" }} active={field.sorting.active} direction={field.sorting.direction}>
                      {field.label}
                    </TableSortLabel>)}
                </Tooltip>)}
            </TableCell>))}
        </TableRow>
      </TableHead>
      <TableBody>
        {results.map((row, idx) => (
        // eslint-disable-next-line react/no-array-index-key
        <TableRow key={idx}>
            {cols.map((fieldDefinition) => (<TableCell key={fieldDefinition.field} sx={fieldDefinition.field === "answer" ||
                    (fieldDefinition.field === "query" && {
                        cursor: "pointer",
                        textDecoration: "underline",
                    })} onClick={() => fieldDefinition.onClick && fieldDefinition.onClick(row)}>
                {fieldDefinition.formatter && get(row, fieldDefinition.field)
                    ? fieldDefinition.formatter(get(row, fieldDefinition.field))
                    : FieldOrNa(get(row, fieldDefinition.field))}
              </TableCell>))}
          </TableRow>))}
      </TableBody>
      <TableFooter>
        {totalRecords >= 1 && (<TableRow>
            <TablePagination loading={false} rowsPerPage={pageSize} rowsPerPageOptions={[10, 25, 50, 100]} page={currentPage} nextIconButtonProps={{ title: formatMessage(messages.next) }} backIconButtonProps={{ title: formatMessage(messages.previous) }} labelRowsPerPage={<FormattedMessage id="tablePagination.rowsPerPage" defaultMessage="Showing:"/>} labelDisplayedRows={labelDisplayedRows} count={totalRecords || 0} onPageChange={(event, page) => onPaginate(page)} onRowsPerPageChange={({ target }) => onChangePageSize(target.value)}/>
          </TableRow>)}
      </TableFooter>
    </Table>);
};
ResultSetComp.propTypes = {
    results: PropTypes.instanceOf(List),
    columns: PropTypes.instanceOf(List),
    currentPage: PropTypes.number,
    totalRecords: PropTypes.number,
    pageSize: PropTypes.number,
    onPaginate: PropTypes.func,
    onChangePageSize: PropTypes.func,
};
ResultSetComp.defaultProps = {
    results: List(),
    columns: List(),
    onChangePageSize: () => null,
    onPaginate: () => null,
    pageSize: 25,
    currentPage: 0,
    totalRecords: 0,
};
export default compose(onlyUpdateForKeys(["results", "columns", "currentPage", "totalRecords"]))(ResultSetComp);
