/* eslint-env browser */
import "./publicPath";
import * as React from "react";
import { RemoveSplash } from "@mnemonic/mui5";
import CardHttpError from "@mnemonic/mui5/CardHttpError";
import PageGenericError from "@mnemonic/mui5/PageGenericError";
import "core-js/stable";
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
/*
  This package exists in @babel/polyfill. The old package is deprecated and this is the new way to do it.
  https://babeljs.io/docs/en/babel-polyfill
*/
import "regenerator-runtime/runtime";
import { IntlProvider } from "@mne-core/intl";
import AppWrapper from "./Application/AppWrapper";
import AppWrapperWithoutSession from "./Application/AppWrapperWithoutSession";
import PromiseBootstrap from "./Bootstrap";
import HttpError from "./Bootstrap/Errors/HttpError";
import Content from "./Content";
import isEnvironmentArgus from "./Helpers/isEnvironmentArgus";
document.addEventListener("DOMContentLoaded", () => {
    if (isEnvironmentArgus()) {
        renderArgusVersion().catch((error) => {
            renderError(error);
        });
    }
    else {
        renderPublicVersion();
    }
});
const renderArgusVersion = () => {
    return PromiseBootstrap().then((values) => {
        const root = createRoot(document.getElementById("root"));
        root.render(<BrowserRouter basename={__PACKAGE__BASENAME__}>
        <AppWrapper {...values}>
          <RemoveSplash />
          <Content />
        </AppWrapper>
      </BrowserRouter>);
    });
};
const renderPublicVersion = () => {
    const root = createRoot(document.getElementById("root"));
    root.render(<BrowserRouter basename={__PACKAGE__BASENAME__}>
      <AppWrapperWithoutSession>
        <RemoveSplash />
        <Content />
      </AppWrapperWithoutSession>
    </BrowserRouter>);
};
const renderError = (ex) => {
    const root = createRoot(document.getElementById("root"));
    root.render(<IntlProvider locale="en-US">
      <RemoveSplash />
      {HttpError.isHttpError(ex) && <CardHttpError error={ex} request={ex.request} response={ex.response}/>}
      {!HttpError.isHttpError(ex) && <PageGenericError title="Unrecoverable error" error={ex}/>}
    </IntlProvider>);
};
